import React, { useCallback } from "react";
import {
  Page,
  Text,
  Card,
  Input,
  Button,
  Spacer,
  Col,
  Row,
  Link,
  Badge,
  Radio,
  useTheme,
} from "@geist-ui/react";
import { ArrowRight } from "@geist-ui/react-icons";

import firebase from "../utils/firebase";
import { useForm } from "react-hook-form";

import { validateEmailFormat } from "../utils/misc";
import { Header, Footer, FieldError, ControlledCheckbox } from "../components";
import { useAuth } from "../contexts/AuthContext";
import { Redirect } from "react-router-dom";
import ControlledRadioGroup from "../components/ControlledRadioGroup";

export default function Checkout() {
  const { isAuthenticated, disableAuthStateChangedHandler } = useAuth();
  const {
    register,
    errors,
    control,
    setError,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      email: "",
      plan: process.env.REACT_APP_MONTHLY_PLAN_ID,
    },
  });

  const theme = useTheme();

  const checkout = useCallback(
    async ({ email, plan }) => {
      try {
        disableAuthStateChangedHandler.current = true;
        await firebase.auth().signOut();
        await firebase.auth().signInAnonymously();
        const firebaseUser = firebase.auth().currentUser;
        await firebaseUser?.updateEmail(email);

        (window as any).Paddle.Checkout.open({
          product: plan,
          email,
          success: "/?success",
        });
      } catch (error) {
        if (error.code === "auth/email-already-in-use") {
          setError("email", {
            message: "This email address is in use. Sign in to buy licenses",
          });

          await firebase.auth().signOut();
        }
      }
    },
    [setError, disableAuthStateChangedHandler]
  );

  if (isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  return (
    <Page size="large">
      <Header />
      <Page.Content>
        <Row justify="center">
          <Col span={12}>
            <Text h1 style={{ textAlign: "center" }}>
              Buy{" "}
              <Text span type="secondary">
                {process.env.REACT_APP_PRODUCT_NAME}
              </Text>
            </Text>
            <Text h6 style={{ textAlign: "center" }}>
              Prices are per user for unlimited boards
            </Text>
            <Spacer y={1} />
            <form onSubmit={handleSubmit(checkout)} autoComplete="off">
              <Card>
                <div style={{ display: "block", width: "80%" }}>
                  <Input
                    name="email"
                    type="email"
                    disabled={isSubmitting}
                    ref={register({
                      required: "This field is required",
                      validate: (value: string) =>
                        validateEmailFormat(value) ||
                        "This e-mail address is incorrect",
                    })}
                    {...(errors.email && {
                      status: "error",
                    })}
                    placeholder="you@domain.com"
                    width="100%"
                  >
                    E-mail address for new account
                  </Input>
                  {errors.email && <FieldError text={errors.email.message} />}
                  <Spacer y={1} />
                  Choose a plan
                  <Spacer y={0.5} />
                  <ControlledRadioGroup
                    name="plan"
                    control={control}
                    rules={{ required: true }}
                    useRow
                  >
                    <Radio value={process.env.REACT_APP_MONTHLY_PLAN_ID}>
                      <>
                        Monthly
                        <Badge
                          size="mini"
                          type="success"
                          style={{
                            marginLeft: "4px",
                            backgroundColor: theme.palette.cyanLighter,
                            color: theme.palette.foreground,
                          }}
                        >
                          popular
                        </Badge>
                      </>
                      <Radio.Desc>$6/month</Radio.Desc>
                    </Radio>
                    <Radio value={process.env.REACT_APP_YEARLY_PLAN_ID}>
                      <>
                        Annual
                        <Badge
                          size="mini"
                          style={{
                            marginLeft: "4px",
                            backgroundColor: theme.palette.violet,
                          }}
                        >
                          75% off
                        </Badge>
                      </>
                      <Radio.Desc>$18/year (only $1.50/mo.)</Radio.Desc>
                    </Radio>
                  </ControlledRadioGroup>
                  {errors.plan && <FieldError text={errors.plan.message} />}
                </div>
              </Card>
              <Spacer y={1} />
              <Row>
                <Col style={{ textAlign: "center" }}>
                  <ControlledCheckbox
                    name="accept"
                    control={control}
                    rules={{ required: true }}
                  >
                    I agree to the{" "}
                    <Link
                      href={process.env.REACT_APP_TERMS_URL}
                      color
                      target="_blank"
                    >
                      Terms of Service
                    </Link>{" "}
                    and{" "}
                    <Link
                      href={process.env.REACT_APP_PRIVACY_URL}
                      color
                      target="_blank"
                    >
                      Privacy Policy
                    </Link>
                    .
                  </ControlledCheckbox>
                  {errors.accept && <FieldError />}
                  <Spacer y={1} />
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    htmlType="submit"
                    type="success"
                    icon={<ArrowRight />}
                    iconRight
                  >
                    Continue
                  </Button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Page.Content>
      <Footer />
    </Page>
  );
}
