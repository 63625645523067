import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Row,
  Page,
  Table,
  Button,
  Divider,
  Col,
  Badge,
  Snippet,
  Text,
  useToasts,
} from "@geist-ui/react";

import { useAuth } from "../contexts/AuthContext";
import { convertDocumentToLicense, db } from "../utils/firebase";
import { License } from "../interfaces";
import { Footer, Header, PageTitle, BuyNow, UnlinkButton } from "../components";
import { download, processRows } from "../utils/csv";
import { useLocation } from "react-router-dom";

function formatDate(date: Date) {
  let month = "" + (date.getUTCMonth() + 1);
  let day = "" + date.getUTCDate();
  let year = date.getUTCFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export default function Dashboard() {
  const { user } = useAuth();
  const [licenses, setLicenses] = useState<Array<License>>([]);
  const [, setToast] = useToasts();
  let location = useLocation();

  useEffect(() => {
    async function fetchLicenses() {
      const licensesSnapshots = await db
        .collection("licenses")
        .where("user", "==", db.doc(`/users/${user!.uid}`))
        .orderBy("expiresAt", "desc")
        .get();

      setLicenses(licensesSnapshots.docs.map(convertDocumentToLicense));
    }

    fetchLicenses();

    if (location.search.includes("success")) {
      setToast({
        text: "You've successfully bought license(s). Thank you!",
        delay: 5000,
        type: "success",
      });
    }

    if (location.search.includes("cancel")) {
      setToast({
        text: "You've canceled the purchase.",
        delay: 5000,
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableData = useMemo(
    () =>
      licenses.map((license, index) => {
        const {
          expiresAt,
          key,
          trelloMemberId,
          trelloMemberFullName,
          cancelUrl,
          updateUrl,
          ...restData
        } = license;

        return {
          number: index + 1,
          key: <Snippet text={key} type="success" symbol="" width="220px" />,
          expiresAt: formatDate(expiresAt),
          usedBy: (
            <Badge size="mini" type={trelloMemberId ? "success" : "default"}>
              {trelloMemberFullName ?? trelloMemberId ?? "FREE TO USE"}
            </Badge>
          ),
          action: trelloMemberId ? (
            <UnlinkButton
              licenseKey={key}
              onSuccess={() => {
                license.trelloMemberId = undefined;
                license.trelloMemberFullName = undefined;
                setLicenses((licenses) => [...licenses]);
              }}
            />
          ) : (
            "-"
          ),
          billing: updateUrl ? (
            <>
              <Button
                ghost
                size="mini"
                onClick={() => {
                  (window as any).Paddle.Checkout.open({
                    override: updateUrl,
                    success: "/",
                  });
                }}
              >
                update
              </Button>
              <Button
                ghost
                type="error"
                size="mini"
                onClick={() => {
                  (window as any).Paddle.Checkout.open({
                    override: cancelUrl,
                    success: "/",
                  });
                }}
              >
                cancel
              </Button>
            </>
          ) : (
            "Cancelled"
          ),
          ...restData,
        };
      }),
    [licenses]
  );

  const exportCsv = useCallback(() => {
    download(
      `Smart Import App Licenses.csv`,
      "text/csv",
      processRows([
        ["License key", "Expires at", "Used by"],
        ...licenses.map((license) => [
          license.key,
          license.expiresAt.toLocaleDateString(),
          license.trelloMemberFullName ?? "-",
        ]),
      ])
    );
  }, [licenses]);

  return (
    <>
      <Page size="large">
        <Header />
        <Page.Content style={{ paddingTop: "2em" }}>
          <PageTitle title="License Manager" subtitle="Manage your licences" />
          <Row>
            <Col style={{ textAlign: "right" }}>
              <BuyNow />
              <Button size="mini" auto onClick={exportCsv}>
                Export CSV
              </Button>
            </Col>
          </Row>
          <Divider />
          <Table data={tableData}>
            <Table.Column prop="number" label="#" width={60} />
            <Table.Column prop="key" label="License Key" width={360} />
            <Table.Column prop="expiresAt" label="Expires At" />
            <Table.Column prop="usedBy" label="Used by" />
            <Table.Column prop="action" label="Action" />
            <Table.Column prop="billing" label="Billing" />
          </Table>
          {tableData.length === 0 && (
            <Text size={12} type="secondary">
              No license yet.
            </Text>
          )}
        </Page.Content>
        <Footer />
      </Page>
    </>
  );
}
