import React, { useCallback } from "react";
import { Page, Text, Button, Divider, Card, Image } from "@geist-ui/react";
import { ExternalLink } from "@geist-ui/react-icons";

import { useAuth } from "../contexts/AuthContext";
import { Footer, Header, PageTitle } from "../components";

export default function Billing() {
  const { user } = useAuth();

  const goToCustomerPortal = useCallback(
    async (event) => {
      event.preventDefault();

      const { stripeCustomerId } = user!;

      const response = await fetch("/api/create-customer-portal-session", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ stripeCustomerId }),
      });

      const session = await response.json();

      window.location.href = session.url;
    },
    [user]
  );

  return (
    <>
      <Page size="large">
        <Header />
        <Page.Content style={{ paddingTop: "2em" }}>
          <PageTitle title="Billing" subtitle="Change your billing details" />
          <Divider />
          <Card>
            <Text h4 style={{ marginBottom: "0" }}>
              Invoices and Payment Methods
            </Text>
            <Text type="secondary">
              All payments are handled by Stripe. Use a link below to go to a
              customer portal and see invoices and payment methods.
            </Text>
            <Card.Footer>
              <Button
                icon={<ExternalLink />}
                iconRight
                onClick={goToCustomerPortal}
                type="success"
                ghost
                auto
              >
                Go to Stripe account
              </Button>
              <Image width={178} src="/Stripe.svg" />
            </Card.Footer>
          </Card>
          {/* <Spacer y={1} />
          <form onSubmit={handleCompanyNameFormSubmit(save)}>
            <Card>
              <Text h4 style={{ marginBottom: "0" }}>
                Your name/Your company's name
              </Text>
              <Text type="secondary">Name is visible on the invoices.</Text>
              <div style={{ width: "50%" }}>
                <Input
                  disabled={isCompanyNameFormSubmitting}
                  width="100%"
                  name="companyName"
                  ref={registerCompanyNameForm({ required: true })}
                  {...(companyNameFormErrors.companyName && {
                    status: "error",
                  })}
                />
                {companyNameFormErrors.companyName && <FieldError />}
              </div>
              <Card.Footer>
                <Button
                  loading={isCompanyNameFormSubmitting}
                  disabled={isCompanyNameFormSubmitting}
                  type="secondary"
                  htmlType={"submit"}
                  auto
                >
                  Save
                </Button>
              </Card.Footer>
            </Card>
          </form>
          <Spacer y={1} />
          <form onSubmit={handleAddressFormSubmit(save)}>
            <Card>
              <Text h4>Address</Text>
              <Spacer y={1.5} />
              <div style={{ width: "50%" }}>
                <Input
                  placeholder="Address line 1"
                  width="100%"
                  disabled={isAddressFormSubmitting}
                  name="addressLine1"
                  ref={registerAddressForm({ required: true })}
                  {...(addressFormErrors.addressLine1 && {
                    status: "error",
                  })}
                />
                {addressFormErrors.addressLine1 && <FieldError />}
                <Spacer y={0.5} />
                <Input
                  placeholder="Address line 2"
                  width="100%"
                  disabled={isAddressFormSubmitting}
                  name="addressLine2"
                  ref={registerAddressForm()}
                  {...(addressFormErrors.addressLine2 && {
                    status: "error",
                  })}
                />
                {addressFormErrors.addressLine2 && <FieldError />}
                <Spacer y={0.5} />
                <Row>
                  <Col span={11}>
                    <Input
                      placeholder="ZIP / Postal code"
                      width="100%"
                      disabled={isAddressFormSubmitting}
                      name="addressZipCode"
                      ref={registerAddressForm({ required: true })}
                      {...(addressFormErrors.addressZipCode && {
                        status: "error",
                      })}
                    />
                    {addressFormErrors.addressZipCode && <FieldError />}
                  </Col>
                  <Col offset={1} span={12}>
                    <Input
                      placeholder="City"
                      width="100%"
                      disabled={isAddressFormSubmitting}
                      name="addressCity"
                      ref={registerAddressForm({ required: true })}
                      {...(addressFormErrors.addressCity && {
                        status: "error",
                      })}
                    />
                    {addressFormErrors.addressCity && <FieldError />}
                  </Col>
                </Row>
                <Spacer y={0.5} />
                <Row>
                  <Col span={11}>
                    <Input
                      placeholder="State / Province / Region"
                      width="100%"
                      disabled={isAddressFormSubmitting}
                      name="addressState"
                      ref={registerAddressForm()}
                      {...(addressFormErrors.addressState && {
                        status: "error",
                      })}
                    />
                    {addressFormErrors.addressState && <FieldError />}
                  </Col>
                  <Col offset={1} span={12}>
                    <ControlledAutoComplete
                      control={controlAddressForm}
                      name="addressCountry"
                      placeholder="Country"
                      rules={{
                        required: "This field is required",
                        validate: (value: string) =>
                          Object.values(isoCountries).includes(value) ||
                          "This field is incorrect",
                      }}
                      disabled={isAddressFormSubmitting}
                      options={Object.entries(isoCountries).map(
                        ([code, name]) => ({
                          label: name,
                          value: name,
                        })
                      )}
                      width="100%"
                      disableFreeSolo
                      {...(addressFormErrors.addressCountry && {
                        status: "error",
                      })}
                    ></ControlledAutoComplete>
                    {addressFormErrors.addressCountry && (
                      <FieldError
                        text={addressFormErrors.addressCountry.message}
                      />
                    )}
                  </Col>
                </Row>
                <Spacer y={0.5} />
              </div>
              <Card.Footer>
                <Button
                  loading={isAddressFormSubmitting}
                  disabled={isAddressFormSubmitting}
                  type="secondary"
                  htmlType="submit"
                  auto
                >
                  Save
                </Button>
              </Card.Footer>
            </Card>
          </form>
          <Spacer y={1} />
          <form onSubmit={handleTaxFormSubmit(save)}>
            <Card>
              <Text h4 style={{ marginBottom: "0" }}>
                Tax ID
              </Text>
              <Text type="secondary">Optional tax id added to invoice.</Text>
              <div style={{ width: "50%" }}>
                <Row>
                  <Col span={8}>
                    <ControlledSelect
                      control={controlTaxForm}
                      rules={{
                        required: watchTaxForm("taxId", false),
                      }}
                      name="taxType"
                      placeholder="Country / Region"
                      disabled={isTaxFormSubmitting}
                      width="100%"
                      {...(taxFormErrors.taxType && {
                        status: "error",
                      })}
                    >
                      <Select.Option value="">None</Select.Option>
                      <Select.Option value="eu_vat">
                        European Union
                      </Select.Option>
                      <Select.Option value="no_vat">Norway</Select.Option>
                      <Select.Option value="in_gst">India</Select.Option>
                      <Select.Option value="au_abn">Australia</Select.Option>
                      <Select.Option value="nz_gst">New Zealand</Select.Option>
                    </ControlledSelect>
                    {taxFormErrors.taxType && <FieldError />}
                  </Col>
                  <Col offset={1} span={15}>
                    <Input
                      placeholder="Number"
                      width="100%"
                      disabled={isTaxFormSubmitting}
                      name="taxId"
                      ref={registerTaxForm({
                        required: watchTaxForm("taxType", false),
                      })}
                      {...(taxFormErrors.taxId && {
                        status: "error",
                      })}
                    />
                    {taxFormErrors.taxId && <FieldError />}
                  </Col>
                </Row>
              </div>
              <Card.Footer>
                <Button
                  loading={isTaxFormSubmitting}
                  disabled={isTaxFormSubmitting}
                  type="secondary"
                  htmlType="submit"
                  auto
                >
                  Save
                </Button>
              </Card.Footer>
            </Card>
          </form> */}
        </Page.Content>
        <Footer />
      </Page>
    </>
  );
}
