import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { GeistProvider, CssBaseline } from "@geist-ui/react";

import AuthProvider from "../contexts/AuthContext";
import { PrivateRoute } from "../components";
import { Dashboard, Login, Billing, Checkout, Settings } from "../pages";

export default function App() {
  useEffect(() => {
    (window as any).Paddle.Setup({
      vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID!),
    });
  });

  return (
    <GeistProvider>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/signup">
              <Login isSignUp />
            </Route>
            <Route path="/checkout">
              <Checkout />
            </Route>
            <PrivateRoute path="/billing">
              <Billing />
            </PrivateRoute>
            <PrivateRoute path="/settings">
              <Settings />
            </PrivateRoute>
            <PrivateRoute exact path="/">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </AuthProvider>
    </GeistProvider>
  );
}
